// Browser-compatible EventEmitter
(function(global) {
    'use strict';

    class EventEmitter {
        constructor() {
            this._events = {};
            this._maxListeners = 10;
        }

        on(type, listener) {
            if (!this._events[type]) {
                this._events[type] = [];
            }

            if (this._events[type].length >= this._maxListeners) {
                console.warn(`Warning: Event '${type}' has exceeded max listeners (${this._maxListeners})`);
            }

            this._events[type].push(listener);
            return this;
        }

        once(type, listener) {
            const onceWrapper = (...args) => {
                this.off(type, onceWrapper);
                listener.apply(this, args);
            };
            return this.on(type, onceWrapper);
        }

        off(type, listener) {
            if (!this._events[type]) return this;

            if (!listener) {
                delete this._events[type];
                return this;
            }

            const index = this._events[type].indexOf(listener);
            if (index !== -1) {
                this._events[type].splice(index, 1);
            }
            return this;
        }

        emit(type, ...args) {
            if (!this._events[type]) return false;

            this._events[type].forEach(listener => {
                try {
                    listener.apply(this, args);
                } catch (error) {
                    console.error('Error in event listener:', error);
                }
            });

            return true;
        }

        listenerCount(type) {
            return this._events[type]?.length || 0;
        }

        setMaxListeners(n) {
            this._maxListeners = n;
            return this;
        }
    }

    // Export to global scope
    global.EventEmitter = EventEmitter;

})(typeof window !== 'undefined' ? window : this); 